<template>
  <div class="contact">
    <div class="df-width contact-tip">
      <div class="contact-title">联系方式</div>
      <div class="contact-label">我们在这里等你的到来</div>
      <div class="contact-box">
        <div class="contact-box-item">
          <el-image class="contact-item-icon" :src="tel" fit="cover"></el-image>
          <div class="contact-box-info">
            <div class="contact-box-label">给我们打电话：</div>
            <div class="contact-box-cont">0756-6332001</div>
          </div>
        </div>
        <div class="contact-box-item">
          <el-image class="contact-item-icon" :src="mail" fit="cover"></el-image>
          <div class="contact-box-info">
            <div class="contact-box-label">给我们发邮件：</div>
            <div class="contact-box-cont">dayuetech@163.com</div>
          </div>
        </div>
        <div class="contact-box-item">
          <el-image class="contact-item-icon" :src="map" fit="cover"></el-image>
          <div class="contact-box-info">
            <div class="contact-box-label">如何找到我们？</div>
            <div class="contact-box-cont">珠海市香洲区海滨南路LOFT新概念商务园A座5016室</div>
          </div>
        </div>
      </div>
    </div>
    <div id="map" class="contact-map"></div>
    <div class="df-width contact-tip">
      <div class="contact-title">合作优势</div>
      <div class="contact-label">我们是一群对客户充满激情和责任感的人，我们强调团队，提倡共赢，善于学习和拼搏，勇于创造和坚持， 满足网络用户的应用需求，为信息化建设不断贡献自己的力量！</div>
    </div>
    <div class="contact-show">
      <div class="df-width contact-show-box">
        <div class="contact-show-item">
          <div class="contact-show-pic">
            <el-image class="contact-show-pic-info" :src="one" fit="cover"></el-image>
          </div>
          <div class="contact-show-title">服务保障</div>
          <div class="contact-show-cont">多年公司运作，专业的营销服务人员</div>
        </div>
        <div class="contact-show-item">
          <div class="contact-show-pic">
            <el-image class="contact-show-pic-info" :src="two" fit="cover"></el-image>
          </div>
          <div class="contact-show-title">技术保障</div>
          <div class="contact-show-cont">多年公司运作，专业的营销服务人员</div>
        </div>
        <div class="contact-show-item">
          <div class="contact-show-pic">
            <el-image class="contact-show-pic-info" :src="three" fit="cover"></el-image>
          </div>
          <div class="contact-show-title">产品保障</div>
          <div class="contact-show-cont">多年公司运作，专业的营销服务人员</div>
        </div>
        <div class="contact-show-item">
          <div class="contact-show-pic">
            <el-image class="contact-show-pic-info" :src="four" fit="cover"></el-image>
          </div>
          <div class="contact-show-title">售后保障</div>
          <div class="contact-show-cont">多年公司运作，专业的营销服务人员</div>
        </div>
      </div>
    </div>
    <div class="contact-form">
      <div class="df-width contact-form-box">
        <div class="contact-form-title">申请合作</div>
        <div class="contact-form-item">
          <div class="contact-form-item-label">公司名称<span>*</span></div>
          <el-input placeholder="请输入公司名称" :input-style="{width: '590px',height:'60px', padding: '22px 20px'}"/>
        </div>
        <div class="contact-form-group">
          <div class="contact-form-item">
            <div class="contact-form-item-label">您的名字<span>*</span></div>
            <el-input placeholder="请输入您的名字" :input-style="{width: '590px',height:'60px', padding: '22px 20px'}"/>
          </div>
          <div class="contact-form-item">
            <div class="contact-form-item-label">手机号码<span>*</span></div>
            <el-input placeholder="请输入手机号码" :input-style="{width: '590px',height:'60px', padding: '22px 20px'}"/>
          </div>
        </div>
        <div class="contact-form-item" style="margin-top: 30px">
          <div class="contact-form-item-label">留下您想了解的信息</div>
          <el-input type="textarea" placeholder="请输入您想了解的信息" resize="none" :input-style="{height:'160px', padding: '22px 20px'}"/>
        </div>
        <div class="contact-btn" @click="addForm">提交信息</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data () {
    return {
      tel: require('@/assets/images/tel2.png'),
      mail: require('@/assets/images/mail2.png'),
      map: require('@/assets/images/map2.png'),
      one: require('@/assets/images/c1.png'),
      two: require('@/assets/images/c2.png'),
      three: require('@/assets/images/c3.png'),
      four: require('@/assets/images/c4.png'),
      height: document.documentElement.clientWidth / 3
    }
  },
  methods: {
    init () {
      const center = new qq.maps.LatLng(22.250805, 113.583685)
      const map = new qq.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: 20,
        disableDefaultUI: false
      })
      const marker = new qq.maps.Marker({
        id: 'marker-layer',
        map: map,
        draggable: false,
        position: center
      })
    },
    addForm () {
      window.alert('该功能暂无开启,如果需要请致电或者邮件我们')
    }
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.height = document.documentElement.clientWidth / 3
      })()
    }
    that.init()
  }
}
</script>

<style scoped>
  .contact-tip {
    margin-top: 60px;
  }
  .contact-title {
    color: var(--color-text);
    font-size: 48px;
    text-align: center;
  }
  .contact-label {
    margin: auto;
    margin-top: 15px;
    font-size: 16px;
    color: var(--color-text);
    text-align: center;
    width: 760px;
    line-height: 30px;
  }
  .contact-box {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }
  .contact-box-item {
    width: 315px;
    display: flex;
  }
  .contact-item-icon {
    height: 36px;
    margin-right: 20px;
  }
  .contact-box-label {
    font-size: 16px;
    color: #666;
  }
  .contact-box-cont {
    margin-top: 13px;
    color: #000;
    font-size: 20px;
    word-break: break-all;
  }
  .contact-map {
    height: 646px;
    margin-top: 40px;
  }
  .contact-show {
    height: 490px;
    padding-top: 60px;
    background-image: url('~assets/images/bg_contact.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
  .contact-show-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contact-show-item {
    width: 280px;
    height: 350px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }
  .contact-show-pic {
    height: 198px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-show-pic-info {
    width: 160px;
  }
  .contact-show-title {
    font-weight: bold;
    color: var(--color-text);
    font-size: 30px;
    text-align: center;
  }
  .contact-show-cont {
    color: var(--color-text);
    font-size: 20px;
    width: 238px;
    margin: auto;
    margin-top: 18px;
    text-align: center;
  }
  .contact-form {
    height: 793px;
    padding-top: 80px;
    background-color: #000;
  }
  .contact-form-title {
    font-size: 48px;
    color: #fff;
    text-align: center;
  }
  .contact-form-item-label {
    color: #fff;
    font-size: 20px;
    margin-bottom: 9px;
  }
  .contact-form-item-label span {
    color: #ff0000;
  }
  .contact-form-group {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contact-btn {
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    margin: auto;
    margin-top: 60px;
    width: 240px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
  }
</style>
